import React from "react"
import PropTypes from "prop-types"

// This is just to test React deployment and compatibility
class ReactHeader extends React.Component {
  render() {
    return (<h1>{this.props.title}</h1>)
  }
}

export default ReactHeader
